<template>
    <div class="dashboard d-flex flex-column pt-2 pb-2" style="height: calc(100dvh - 5.5rem);">
        <VuePerfectScrollbar class="card flex-fill p-2 mb-2 pr-2"
                             :settings="{ suppressScrollX : true }">
            <avgAmountOnRechargeTime :options="{ minAmount: 1500, maxAmount: 3000, maxRechargeTimeGet: 10 }"
                                     title="VIP - Average amount by number of deposits" />
            <hr />
            <avgAmountOnRechargeTime :options="{ minAmount: 3000, maxAmount: 99999999, maxRechargeTimeGet: 10 }"
                                     title="PRIME - Average amount by number of deposits" />
            <hr />
            <avgAmountOnRechargeTime :options="{ minAmount: 0, maxAmount: 1500, maxRechargeTimeGet: 10 }"
                                     title="NORMAL - Average amount by number of deposits" />
            <hr />
            <statisticOnSpecialUser title="Recharge timeline of special user" />
        </VuePerfectScrollbar>
    </div>
</template>

<script>
    import '@/assets/admin-css/dashboard.css';
    import baseComponent from "@/scripts/baseComponent";
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    import { mapState } from "vuex";
    export default {
        extends: baseComponent,
        components: {
            //AvgAmountOnRechargeTime
            avgAmountOnRechargeTime: lazyLoadComponent({
                componentFactory: () => import("@/views/dashboard/chartComponents/AvgAmountOnRechargeTime.vue"),
                loading: SkeletonBox,
            }),
            statisticOnSpecialUser: lazyLoadComponent({
                componentFactory: () => import("@/views/dashboard/chartComponents/StatisticOnSpecialUser.vue"),
                loading: SkeletonBox,
            }),
        },
        data() {
            return {
                
            };
        },
        computed: {
            ...mapState({
                userInfo: state => state.auth.user,
                rolesInfo: state => state.auth.roles,
            }),
        },
        mounted() {

        },
        beforeDestroy() {

        },
        methods: {
            
        }
    }
</script>